body {
  display: flex;
  justify-content: center;
}

main {
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-bottom: 4em;
  min-width: 400px;
}

h3.justify {
  text-align: center;
  text-align-last: justify;
}

.target-date {
  text-align: center;
  font-size: 1.1em;
}

.fat {
  font-size: 1.4em;
}

.red {
  color: red;
}

.yellow {
  color: gold;
}

.green {
  color: green;
}

tr.estimate {
  background-color: #a29809;
}

tbody.estimate tr:last-of-type {
  border-bottom: 2px solid #a29809;
}

tr.past {
  background-color: #095798;
}

tbody.past tr:last-of-type {
  border-bottom: 2px solid #095798;
}

tr.journey {
  background-color: #6f42c1;
}

tbody.journey tr:last-of-type {
  border-bottom: 2px solid #6f42c1;
}

table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 1em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

th,
td {
  padding: 12px 15px;
  text-align: center;
}

tbody tr {
  border-bottom: 1px solid #dddddd;
}

tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.progress-container {
  margin-top: 0.5em;
}

.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  padding: 3px;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

.progress-bar-fill-loss {
  display: block;
  height: 22px;
  background-color: #009879;
  border-radius: 3px;

  transition: width 500ms ease-in-out;
}

.progress-bar-fill-gain {
  display: block;
  height: 22px;
  background-color: #c93402;
  border-radius: 3px;

  transition: width 500ms ease-in-out;
}

.progress-bar-fill-days {
  display: block;
  height: 22px;
  background-color: #004298;
  border-radius: 3px;

  transition: width 500ms ease-in-out;
}

.tooltip {
  position: relative;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.previous-weights {
  padding-bottom: 3em;
}

.vertical-container {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  font-size: 0.5em;
  text-align: center;
  margin-right: 3.3px;
}

.block {
  width: 10px;
  height: 10px;
  margin-bottom: 1px;
}

@media (min-width: 801px) {
  .desktop-layout {
    display: flex;
    gap: 4em;
    justify-content: space-around;
  }

  .block {
    width: 26px;
    height: 26px;
    margin-bottom: 1px;
  }
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.nav-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #009879; /* Green color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.nav-button.prev {
  background-color: #007bff; /* Blue color for Previous */
}

.nav-button.next {
  background-color: #28a745; /* Green color for Next */
}

.nav-button:hover {
  background-color: #0056b3; /* Darker blue for Previous on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

.nav-button.next:hover {
  background-color: #218838; /* Darker green for Next on hover */
}

.nav-button:active {
  transform: translateY(0); /* Reset lift effect on click */
}

@keyframes celebrateBorder {
  0% {
    box-shadow: 0 0 0 0px gold;
  }
  50% {
    box-shadow: 0 0 0 4px gold;
  }
  100% {
    box-shadow: 0 0 0 0px gold;
  }
}
